import React, { useState, useEffect } from 'react'
import { Row, Col, Radio, Select, Button, Input, Spin, Form, notification, Modal, Checkbox } from 'antd'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import client from '../../api/client'
import PriceSelect from '../base/priceSelect'
import PartyChooser from './partyChooser'
import 'bootstrap/scss/bootstrap.scss'
import OrderTypeSelection from './orderTypeSelection'
import { isOwner } from '../../api/auth'
import OrderSuccessImg from '../../images/ordersuccess.svg'

export default function NewOrder(props) {
  const [newOrderForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [senderSelected, setSenderSelected] = useState(0)
  const [receiverSelected, setReceiverSelected] = useState(0)
  const [branchOptions, setBranchOptions] = useState([])
  const [selectedPrice, setSelectedPrice] = useState(0)
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([])
  const [errorValidateParty, setErrorValidateParty] = useState(false)
  const [errorValidateOrderType, setErrorValidateOrderType] = useState(false)
  const [errorValidatePrice, setErrorValidatePrice] = useState(false)
  const [orderSuccessModalVisible, setOrderSuccessModalVisible] = useState(false)

  useEffect(() => {
    const init = async () => {
      const res = await client.get('/api/lookup/branches')
      const branchOptions = res.data
        .filter((x) => x.isReceiveOnline)
        .map((el) => {
          return { label: el.address, value: el.id }
        })
      setBranchOptions(branchOptions)
    }
    init()
  }, [])

  const validate = () => {
    if (!senderSelected || !receiverSelected) setErrorValidateParty(true)
    if (selectedOrderTypes.length === 0) setErrorValidateOrderType(true)
    if (!selectedPrice > 0) setErrorValidatePrice(true)

    if (!senderSelected || !receiverSelected || selectedOrderTypes.length === 0 || !selectedPrice > 0) {
      return false
    } else return true
  }

  const submitOrder = async () => {
    setLoading(true)
    await newOrderForm
      .validateFields()
      .then(async (values) => {
        const isValid = validate()
        if (isValid) {
          const data = {
            price: selectedPrice,
            orderItemTypes: selectedOrderTypes,
            branchId: values.branch,
            senderId: senderSelected,
            receiverId: receiverSelected,
            sourceType: 'Web',
            shipmentType: values.shipmentType,
            paymentType: values.paymentType,
            customerComment: values.customerComment,
            willReceiveMessage: values.willReceiveMessage,
          }
          if (props.bulkId) {
            await client
              .post(`/api/orders/${props.bulkId}`, data)
              .then(async (res) => {
                setOrderSuccessModalVisible(true)
                // notification.success({ message: 'Илгээмж үүслээ' })
                // navigate(`/bulk/${props.bulkId}`)
              })
              .catch((error) => {
                console.error(error.message)
                notification.error({ message: 'Алдаа гарлаа' })
              })
          } else {
            await client.post('/api/orders', data)
            setOrderSuccessModalVisible(true)
            // notification.success({ message: 'Илгээмж үүслээ' })
            // navigate('/')
          }
        }
      })
      .catch((info) => {
        console.error('Validate Failed:', info)
      })
    setLoading(false)
  }
  return (
    <Spin spinning={loading}>
      <Form form={newOrderForm} initialValues={{ willReceiveMessage: true }} layout="vertical">
        <Row className="mb-1">
          <Col span={20}>
            <Form.Item
              name="shipmentType"
              label="Илгээмжийн төрлөө сонгоно уу"
              rules={[
                {
                  required: true,
                  message: 'Илгээмжийн төрлөө оруулна уу!',
                },
              ]}
            >
              <Radio.Group className="sb-radio-buttons">
                <Radio value="Air">Агаарын ачаа</Radio>
                <Radio value="Ground">Газрын ачаа</Radio>
                {/* <Radio value="Express">Express ачаа</Radio> */}
                {isOwner() ? <Radio value="Container">Чингэлэгийн ачаа</Radio> : []}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col xl={10} lg={12} md={16} sm={18} span={24}>
            <Form.Item
              name="branch"
              label="Хүлээн авах салбараа сонгоно уу"
              rules={[
                {
                  required: true,
                  message: 'Хүлээн авах салбараа сонгоно уу!',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Сонгох"
                className="sb-select mb-2"
                popupClassName="sb-select-dropdown"
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="lg" style={{ color: '#11427F', top: '49%' }} />}
                options={branchOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <PartyChooser
              setSenderCallback={(e) => {
                setSenderSelected(e)
                if (receiverSelected) setErrorValidateParty(false)
              }}
              setReceiverCallback={(e) => {
                setReceiverSelected(e)
                if (senderSelected) setErrorValidateParty(false)
              }}
            />
            {errorValidateParty && <div style={{ color: '#ff4d4f', fontSize: 16 }}>Илгээгч болон хүлээн авагчаа сонгоно уу</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24} className="mb-2" style={{ fontSize: 18 }}>
            Явуулж буй илгээмжийн үнийн дүнгээ сонгоно уу:
          </Col>
          <Col span={24} className="mb-1">
            <PriceSelect
              isPriceCustom={false}
              defaultValue={0}
              parentCallback={(e) => {
                if (e > 0) {
                  setSelectedPrice(e)
                  setErrorValidatePrice(false)
                } else setErrorValidatePrice(true)
              }}
            />
            {errorValidatePrice && <div style={{ color: '#ff4d4f', fontSize: 16 }}>Илгээмжийн үнийн дүнг оруулна уу!</div>}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col span={24}>
            <OrderTypeSelection
              isKiosk={false}
              defaultSelectedOrderTypes={false}
              refreshOrderItemTypeList={(selectedTypes) => {
                setSelectedOrderTypes(selectedTypes)
                if (selectedTypes.length > 0) setErrorValidateOrderType(false)
                else setErrorValidateOrderType(true)
              }}
            />
            {errorValidateOrderType && <div style={{ color: '#ff4d4f', fontSize: 16 }}>Илгээмжийн төрлөө сонгоно уу!</div>}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="paymentType"
              label="Төлбөрийн төрлөө сонгоно уу"
              rules={[
                {
                  required: true,
                  message: 'Төлбөрийн төрлөө сонгоно уу!',
                },
              ]}
            >
              <Radio.Group className="sb-radio-buttons">
                <Radio value="Cash">Бэлэн</Radio>
                <Radio value="Transfer">Шилжүүлэг</Radio>
                <Radio value="Card">Карт</Radio>
                <Radio value="InMongolia">Монголд</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item name="customerComment" label="Тайлбар">
              <Input.TextArea
                rows={4}
                className="sb-input-area"
                style={{ padding: 5, border: '1px solid gray', borderRadius: 4 }}
                placeholder="Тайлбараа энд оруулна уу..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item valuePropName="checked" name="willReceiveMessage">
            <Checkbox className="sb-checkbox">
              <div style={{ marginLeft: 4, fontSize: 18 }}>Мэссэж болон и-мейл хүлээн авах уу?</div>
            </Checkbox>
          </Form.Item>
        </Row>
        <Row className="mb-5 mt-4">
          <Col span={16} offset={4}>
            <Button className="btn-sb btn-sb-primary w-100" style={{ fontSize: 18, height: 50, fontWeight: '500' }} onClick={submitOrder}>
              Бүртгэх
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal
        open={orderSuccessModalVisible}
        onCancel={() => navigate('/')}
        className="sb-modal"
        width={400}
        destroyOnClose
        centered
        maskStyle={{ background: '#11427FB9' }}
        footer={[
          <Row justify="center" key={'modal'} gutter={[12, 24]}>
            <Col span={12}>
              {props.isBulk ? (
                <Button className="btn-sb btn-sb-primary w-100" onClick={() => navigate(`/bulk/${props.bulkId}`)}>
                  Дуусгах
                </Button>
              ) : (
                <Button className="btn-sb btn-sb-primary w-100" onClick={() => navigate(`/`)}>
                  Дуусгах
                </Button>
              )}
            </Col>
            <Col span={12}>
              <Button
                className="btn-sb btn-sb-primary w-100"
                onClick={() => {
                  if (typeof window !== 'undefined') window.location.reload()
                }}
              >
                Дахин бүртгэх
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <div
          style={{
            height: '260px',
            paddingTop: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
          }}
        >
          <div style={{ position: 'absolute', top: '-68px', left: '143px' }}>
            <img src={OrderSuccessImg} alt="img" style={{ width: '180px' }} />
          </div>
          <div>
            <div style={{ fontSize: 22, textAlign: 'center' }}>Таны ачаа манай системд амжилттай бүртгэгдлээ.</div>
            <div style={{ fontSize: 16, textAlign: 'center' }}>Ачааны дэлгэрэнгүй мэдээллийг вэб порталаас харна уу.</div>
          </div>
        </div>
      </Modal>
    </Spin>
  )
}
